import React from 'react';
import { PropTypes } from 'prop-types';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';
import { palette } from '../../settings/themes/colors';
import { FormulationActivityForm } from './FormulationActivityForm';
import { Modal } from '../molecules/Modal';

const styles = makeStyles({
  root: {
    display: 'flex',
    '& i': {
      color: palette.candy
    },
    '& hr': {
      background: palette.yellow
    }
  },
  card: {
    margin: 'auto',
    minWidth: 760,
    maxWidth: 800,
    color: palette.dimGray
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: lightThemeV2.spacing(4),
    paddingTop: lightThemeV2.spacing(4),
    paddingLeft: lightThemeV2.spacing(8),
    paddingRight: lightThemeV2.spacing(8),
    '&:last-child': {
      paddingBottom: lightThemeV2.spacing(8)
    }
  }
});

const useFormulationActivityPopUp = () => styles();

export const FormulationActivityPopUp = ({ formulationLevel, onClose = () => {}, initialValues }) => {
  const classes = useFormulationActivityPopUp();

  const handleClose = () => {
    onClose();
  };

  const handlerSubmit = values => {
    onClose(values);
  };

  const modalTitle = (
    <Grid spacing={2} container>
      <Typography variant="h6" style={{ fontWeight: 900 }}>
        <FormattedMessage id="common.add" />
        {formulationLevel.name}
      </Typography>
    </Grid>
  );

  return (
    <Modal
      open={true}
      title={modalTitle}
      subtitle={<FormattedMessage id="beneficiaries.form.mandatoryMessage" />}
      onClose={handleClose}
      className={classes.root}
    >
      <FormulationActivityForm taskLevel={formulationLevel} initialValues={initialValues} onSubmit={handlerSubmit} onCancel={handleClose} />
    </Modal>
  );
};

FormulationActivityPopUp.propTypes = {
  formulationLevel: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    levelAliasId: PropTypes.number,
    parent: PropTypes.shape({
      name: PropTypes.string,
      tasks: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          description: PropTypes.string,
          budget: PropTypes.number,
          plannedToStartAt: PropTypes.string,
          plannedToCompleteAt: PropTypes.string,
          responsible: PropTypes.shape({
            id: PropTypes.number,
            fullName: PropTypes.string
          })
        })
      )
    })
  }),
  initialValues: PropTypes.any,
  onClose: PropTypes.func
};
