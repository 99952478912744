import { useMutation, useQuery } from '@tanstack/react-query';
import { getApi } from '../api';
import { transformCamel2Snake, transformSnake2Camel } from './utils';
import { BUDGET_STATUS } from '../containers/Projects/components/project/Budget/BudgetProvider';

export const useGetBudgetLines = ({ taskId }) => {
  const queryFn = async context => {
    const [params] = context.queryKey;
    const result = await getApi().get(`/tasks/${params.taskId}/task_budget_lines`);

    return transformSnake2Camel(result.data);
  };

  return useQuery({
    queryKey: [{ taskId }, 'GET_budgetLinesByTaskId'],
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useGetBudget = contractId => {
  const queryFn = async context => {
    const [id] = context.queryKey;
    const result = await getApi().get(`contracts/${id}/budget/`);

    return transformSnake2Camel(result.data);
  };

  return useQuery({
    queryKey: [contractId, 'GET_budgetContract'],
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useCreateBudgetLine = ({ onSuccess = () => {} }) =>
  useMutation(
    ({ taskId, data }) =>
      getApi()
        .post(`/tasks/${taskId}/task_budget_lines`, { task_budget_line: transformCamel2Snake(data) })
        .then(axiosResult => axiosResult.data),
    {
      onSuccess: result => {
        onSuccess(transformSnake2Camel(result));
      }
    }
  );

export const useUpdateBudgetLine = ({ onSuccess = () => {} }) =>
  useMutation(
    ({ taskId, id, data }) =>
      getApi()
        .put(`/tasks/${taskId}/task_budget_lines/${id}`, { task_budget_line: transformCamel2Snake(data) })
        .then(axiosResult => axiosResult.data),
    {
      onSuccess: result => {
        onSuccess(transformSnake2Camel(result));
      }
    }
  );

export const useGetItemBudget = clientId => {
  const queryFn = async context => {
    const [id] = context.queryKey;
    const result = await getApi().get(`clients/${id}/budget_categories`);

    return transformSnake2Camel(result.data);
  };

  return useQuery({
    queryKey: [clientId, 'GET_GetItemBudget'],
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useGetSubItemBudget = query => {
  const queryFn = async context => {
    const [{ clientId, parentId }] = context.queryKey;

    if (!parentId) {
      return [];
    }

    const result = await getApi().get(`clients/${clientId}/budget_categories?parent_id=${parentId}`);

    return transformSnake2Camel(result.data);
  };

  return useQuery({
    queryKey: [query, `GET_GetItemBudget?parent_id=${query.parentId}`],
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 30
  });
};

export const useGetItemBudgetInBulk = query => {
  const queryFn = async context => {
    const [{ ids = [] }] = context.queryKey;

    if (ids.length === 0) {
      return [];
    }
    const results = await Promise.all(ids.map(taskId => getApi().get(`tasks/${taskId}/task_budget_lines`)));

    return transformSnake2Camel(results.map(result => result.data).reduce((acc, val) => acc.concat(val), []));
  };

  return useQuery({
    queryKey: [query, `GET_budgetLinesByTaskId?bulk=true`],
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 30
  });
};

export const useDeleteBudgetLine = ({ onSuccess = () => {} }) =>
  useMutation(
    ({ taskId, id }) =>
      getApi()
        .delete(`/tasks/${taskId}/task_budget_lines/${id}`)
        .then(axiosResult => axiosResult.data),
    { onSuccess }
  );

export const useGetTaskBudgetDetail = query => {
  const queryFn = async context => {
    const [{ taskId, budgetLineId }] = context.queryKey;

    const result = await getApi().get(`/tasks/${taskId}/task_budget_lines/${budgetLineId}`);

    return transformSnake2Camel(result.data);
  };

  return useQuery({
    queryKey: [query, `GET_taskBudgetDetail`],
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useApproveBudget = ({ onSuccess = () => {} }) =>
  useMutation(
    ({ budgetId }) =>
      getApi()
        .patch(`/budgets/${budgetId}/update_status`, { status: BUDGET_STATUS.APPROVED })
        .then(axiosResult => axiosResult.data),
    {
      onSuccess: result => {
        onSuccess(result);
      }
    }
  );

export const useRequestApproveBudget = ({ onSuccess = () => {} }) =>
  useMutation(
    ({ budgetId }) =>
      getApi()
        .patch(`/budgets/${budgetId}/update_status`, { status: BUDGET_STATUS.UNDER_REVIEW })
        .then(axiosResult => axiosResult.data),
    {
      onSuccess: result => {
        onSuccess(result);
      }
    }
  );

export const useCreateCommentBudget = ({ onSuccess = () => {} }) =>
  useMutation(
    ({ budgetLineId, data }) =>
      getApi()
        .post(`/task_budget_lines/${budgetLineId}/create_rejection_notes`, {
          rejection_notes: [{ note: data.comment }]
        })
        .then(axiosResult => axiosResult.data),
    {
      onSuccess: result => {
        onSuccess(transformSnake2Camel(result));
      }
    }
  );

export const useResolveCommentBudget = ({ onSuccess = () => {} }) =>
  useMutation(
    ({ budgetLineId, budgetLineRejectionNoteId }) =>
      getApi()
        .patch(`/task_budget_lines/${budgetLineId}/task_budget_line_rejection_notes/${budgetLineRejectionNoteId}`, {
          rejection_note: { resolved: true }
        })
        .then(axiosResult => axiosResult.data),
    {
      onSuccess: result => {
        onSuccess(transformSnake2Camel(result));
      }
    }
  );
