import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, makeStyles, Modal as BaseModal, Card, CardContent, Button, Box } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import { palette } from '../../settings/themes/colors';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';

const styles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& i': {
      color: palette.candy
    }
  },
  card: {
    minWidth: 720,
    maxWidth: 720,
    color: palette.dimGray
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: lightThemeV2.spacing(4),
    paddingTop: lightThemeV2.spacing(4),
    paddingLeft: lightThemeV2.spacing(8),
    paddingRight: lightThemeV2.spacing(8),
    '&:last-child': {
      paddingBottom: lightThemeV2.spacing(8)
    }
  },
  closeIcon: {
    textAlign: 'right',
    cursor: 'pointer',
    color: palette.cerulean,
    height: lightThemeV2.spacing(5)
  }
});

const useModalStyle = () => styles();

export const ConfirmationModal = ({ open, onClose, tittle: title, children, onConfirm, onCancel, confirmText, hideButtons }) => {
  const classes = useModalStyle();

  return (
    <BaseModal open={open} onClose={onClose} className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Box className={classes.closeIcon}>
                <CloseOutlined onClick={onCancel} />
              </Box>
              <Typography variant="h5">{title}</Typography>
            </Grid>
            <Grid item>{children}</Grid>
          </Grid>
          {!hideButtons && (
            <Grid container spacing={2}>
              <Grid item>
                <Button variant="contained" onClick={onConfirm}>
                  {confirmText || <FormattedMessage id="common.delete" />}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={onCancel}>
                  <FormattedMessage id="common.cancel" />
                </Button>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
    </BaseModal>
  );
};

ConfirmationModal.propTypes = {
  tittle: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  confirmText: PropTypes.string || PropTypes.object,
  hideButtons: PropTypes.bool
};
