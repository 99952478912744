import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  Paper,
  Radio,
  RadioGroup,
  Typography
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import Search from '@material-ui/icons/Search';
import MuiButton from '@material-ui/core/Button';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useContactsFiltersClasses } from './BeneficiariesFilters.style';
import { FormField } from '../atoms/FormField';
import { AgeSelector } from '../molecules/AgeSelector';
import { ProjectSelector } from '../molecules/ProjectSelector';
import { CountrySelector } from '../molecules/CountrySelector';
import { ProfessionsSelector } from '../molecules/ProfessionsSelector';
import { EducationalLevelSelector } from '../molecules/EducationalLevelSelector';
import { ETHNICITY_TYPES, INITIAL_EXTRA_FILTERS_VALUES, INITIAL_FILTERS_VALUES } from '../../containers/Beneficiaries/constants';
import { LegalStatusSelector } from '../molecules/LegalStatusSelector';
import { IndustrySelector } from '../molecules/IndustriesSelector';
import { SelectField } from '../molecules/SelectField';

export const BeneficiariesFilters = ({ filters, refetch, clientId, contactType }) => {
  const { messages: intlMessages } = useIntl();
  const classes = useContactsFiltersClasses();
  const [localSearch, setLocalSearch] = useState('');
  const [isMoreFiltersActive, setIsMoreFiltersActive] = useState(false);
  const { setFieldValue, getFieldProps, setValues, values } = filters || {};
  const handleMoreFiltersChange = () => {
    setIsMoreFiltersActive(!isMoreFiltersActive);
  };

  const handleEraseFilters = () => {
    setValues(INITIAL_FILTERS_VALUES);
  };

  useEffect(() => {
    if (isMoreFiltersActive) {
      setValues({
        ...values,
        ...INITIAL_EXTRA_FILTERS_VALUES
      });
    }
  }, [isMoreFiltersActive]);

  const commonFiltersInputs = [
    {
      label: intlMessages['common.status'],
      name: 'active',
      xs: 2.5,
      component: ({ ...props }) => (
        <FormControl className={classes.fieldContainer}>
          <FormLabel className={classes.label}>{intlMessages['common.status']}:</FormLabel>
          <RadioGroup row {...props}>
            <FormControlLabel value="true" control={<Radio />} label={intlMessages['beneficiaries.active']} />
            <FormControlLabel value="false" control={<Radio />} label={intlMessages['beneficiaries.inActive']} />
          </RadioGroup>
        </FormControl>
      )
    },
    {
      xs: 1.5,
      moveLeft: true,
      component: () => (
        <Grid className={classes.filterButtonContainer}>
          <MuiButton variant="text" onClick={handleMoreFiltersChange} className={classes.button}>
            <Typography variant="button" className={classes.label}>
              {intlMessages['common.moreFilters']}
            </Typography>
            {isMoreFiltersActive ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
          </MuiButton>
        </Grid>
      )
    }
  ];

  const commonExtraFiltersInputs = [
    {
      label: intlMessages['project.details.location'],
      name: 'country_id',
      xs: 12,
      component: ({ ...props }) => <CountrySelector clientId={clientId} {...props} />
    }
  ];

  const naturalFilterInputs = [
    {
      label: intlMessages['beneficiaries.age'],
      name: 'age',
      xs: 3,
      component: ({ ...props }) => <AgeSelector {...props} filters={filters} />
    },
    {
      label: intlMessages['beneficiaries.form.ethnicity'],
      name: 'ethnicity',
      xs: 3,
      customSettings: { keys: ETHNICITY_TYPES, translationSuffix: 'beneficiaries.form.' },
      component: SelectField
    },
    {
      label: intlMessages['beneficiaries.form.gender'],
      name: 'sex',
      xs: 2.5,
      component: ({ ...props }) => (
        <FormControl className={classes.fieldContainer}>
          <FormLabel className={classes.label}>{intlMessages['beneficiaries.form.gender']}:</FormLabel>
          <RadioGroup row {...props}>
            <FormControlLabel value="male" control={<Radio />} label={intlMessages['common.male']} />
            <FormControlLabel value="female" control={<Radio />} label={intlMessages['common.female']} />
          </RadioGroup>
        </FormControl>
      )
    },
    {
      xs: 0,
      component: () => <Divider orientation="vertical" flexItem className={classes.divider} />
    },
    ...commonFiltersInputs
  ];

  const legalFilterInputs = [
    {
      label: intlMessages['beneficiaries.legal.status'],
      name: 'legal_status',
      xs: 3,
      component: LegalStatusSelector
    },
    {
      label: intlMessages['beneficiaries.form.activity'],
      name: 'industry_id',
      xs: 3,
      component: IndustrySelector
    },
    ...commonFiltersInputs
  ];

  const naturalExtraFiltersInputs = [
    {
      label: intlMessages['beneficiaries.associateToProject'],
      name: 'project_id',
      component: ({ ...props }) => <ProjectSelector clientId={clientId} {...props} />
    },
    {
      label: intlMessages['beneficiaries.educationalLevel'],
      name: 'educational_level',
      component: EducationalLevelSelector
    },
    {
      label: intlMessages['beneficiaries.form.profession'],
      name: 'profession_id',
      component: ProfessionsSelector
    },
    ...commonExtraFiltersInputs
  ];

  const LegalExtraFiltersInputs = [
    {
      label: intlMessages['beneficiaries.associateToProject'],
      name: 'project_id',
      xs: 12,
      component: ({ ...props }) => <ProjectSelector clientId={clientId} {...props} />
    },
    ...commonExtraFiltersInputs
  ];

  const filtersConfig = {
    natural: {
      filterInputs: naturalFilterInputs,
      extraFilters: naturalExtraFiltersInputs
    },
    legal: {
      filterInputs: legalFilterInputs,
      extraFilters: LegalExtraFiltersInputs
    }
  };

  const { filterInputs = [], extraFilters = [] } = filtersConfig[contactType];

  const debouncedHandleChange = useCallback(
    _.debounce(value => {
      setFieldValue('query', value);
      refetch();
    }, 700),
    []
  );

  const handleSearchChange = event => {
    const value = event.target.value;

    setLocalSearch(value);
    debouncedHandleChange(value);
  };

  const searchInputUI = (
    <Grid className={classes.fieldContainer}>
      <Grid item className={classes.SearchLabel}>
        <Typography variant="body1" component="span">
          {intlMessages['beneficiaries.search.message']}
        </Typography>
      </Grid>
      <Grid item>
        <FormField
          name="query"
          variant="outlined"
          className={classes.searchInput}
          placeholder={`${intlMessages['searchBar.label']}...`}
          value={localSearch}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            )
          }}
        />
      </Grid>
    </Grid>
  );

  const filtersUI = (
    <>
      <Grid container spacing={4}>
        {filterInputs.map(({ xs, moveLeft, ...field }, index) => (
          <Grid item xs={xs} key={`field-${field.name}-${index}`} className={moveLeft && classes.moveLeft}>
            <FormField key={field.name} {...getFieldProps(field.name)} {...field} />
          </Grid>
        ))}
      </Grid>
      {isMoreFiltersActive && (
        <Grid container spacing={3} className={classes.extraFiltersContainer}>
          {extraFilters.map(({ xs = 4, ...field }, index) => (
            <Grid item xs={xs} key={`field-${field.name}-${index}`}>
              <FormField key={field.name} {...getFieldProps(field.name)} {...field} />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );

  const eraseFiltersUI = (
    <Grid item xs={12} key={'field-erase-filters'}>
      <Grid className={classes.filterButtonContainer}>
        <MuiButton variant="text" onClick={handleEraseFilters} className={classes.button}>
          <Typography variant="button" className={classes.label}>
            {'BORRAR FILTROS'}
          </Typography>
        </MuiButton>
      </Grid>
    </Grid>
  );

  return (
    <Grid className={classes.root}>
      <Paper className={classes.paper}>
        <form>
          <Grid className={classes.container}>
            <Typography variant="h6" className={classes.heading}>
              {intlMessages['common.filters']}
            </Typography>
            {searchInputUI}
          </Grid>
          {filtersUI}
          {eraseFiltersUI}
        </form>
      </Paper>
    </Grid>
  );
};

BeneficiariesFilters.propTypes = {
  filters: PropTypes.object,
  clientId: PropTypes.number,
  refetch: PropTypes.func,
  contactType: PropTypes.string
};
